<template>
	<div class="container">
		<div class="content-box">
			<img class="logo" src="~@/assets/images/logo.png"/>
			<p class="title">客户支持系统</p>
			<p class="sub-title">通过登录所使用的邮箱找回登录密码</p>
			<el-form :model="stepForm1" :rules="stepRules1" ref="stepForm1" class="login-form" @submit.native.prevent v-if="step == 1">
				<el-form-item prop="username">
					<el-input type="text" v-model="stepForm1.username" autocomplete="off" placeholder="输入邮箱地址"
						prefix-icon="el-icon-message" class="item-input" :clearable="true"></el-input>
				</el-form-item>
				<el-form-item class="item-input">
					<el-row :gutter="16">
						<el-col :span="12">
							<el-form-item prop="verifycode">
								<el-input type="text" v-model="stepForm1.verifycode" autocomplete="off" placeholder="图片验证码"
									prefix-icon="el-icon-document-checked" :clearable="true"></el-input>
							</el-form-item>
						</el-col>
						<el-col :span="12">
							<el-tooltip content="计算结果为验证码，可点击刷新" placement="bottom" effect="light" :placement="placement">
								<div class="check-img-box" @click="changeVerifycodeImgUrl()">
									<img class="verifycode-img" :src="verifycodeImgUrl"/>
								</div>
							</el-tooltip>
						</el-col>
					</el-row>
				</el-form-item>
				<el-form-item>
					<el-button native-type="=submit" type="primary" @click="submitStep1('stepForm1')" class="item-button">下一步</el-button>
				</el-form-item>
			</el-form>
			
			<el-form :model="stepForm2" :rules="stepRules2" ref="stepForm2" class="login-form" @submit.native.prevent v-else-if="step == 2">
				<el-form-item class="item-input" v-show="isverifycode">
					<el-row :gutter="16">
						<el-col :span="12">
							<el-form-item prop="verifycode">
								<el-input type="text" v-model="stepForm2.verifycode" autocomplete="off" placeholder="图片验证码"
									prefix-icon="el-icon-document-checked" :clearable="true"></el-input>
							</el-form-item>
						</el-col>
						<el-col :span="12">
							<el-tooltip content="计算结果为验证码，可点击刷新" placement="bottom" effect="light" :placement="placement">
								<div class="check-img-box" @click="changeVerifycodeImgUrl()">
									<img class="verifycode-img" :src="verifycodeImgUrl"/>
								</div>
							</el-tooltip>
						</el-col>
					</el-row>
				</el-form-item>
				<el-form-item class="item-input">
					<el-row :gutter="16">
						<el-col :span="12">
							<el-form-item prop="smsCode">
								<el-input type="text" v-model="stepForm2.smsCode" placeholder="验证码"
									prefix-icon="el-icon-edit-outline" :clearable="true"></el-input>
							</el-form-item>
						</el-col>
						<el-col :span="12">
							<el-button plain style="width: 100%;" :disabled="isCkCodeBtn" @click="getCode()">{{codeBtnText}}</el-button>
						</el-col>
					</el-row>
				</el-form-item>
				<el-form-item prop="password">
					<el-input placeholder="设置6~18位新密码，非纯数字" v-model="stepForm2.password" prefix-icon="el-icon-lock" class="item-input" autocomplete="off" show-password></el-input>
				</el-form-item>
				<el-form-item>
					<el-button native-type="=submit" type="primary" @click="submitStep2('stepForm2')" class="item-button">提 交</el-button>
				</el-form-item>
			</el-form>
			<div class="tips-box">
				<router-link to="/" class="return">返回登录</router-link>
			</div>
		</div>
	</div>
</template>

<script>
	import { getCaptcha, sendSms, retrievePassword } from "@/api/home/index";
	import _ from "lodash";
	import md5 from "md5";
	export default {
		data() {
			const checkPassword = (rule, value, cb) => {
				if (!value) {
					cb(new Error("请设置登录密码"));
				} else {
					let passwdRule = /^(?![0-9]+$)[0-9A-Za-z|a-zA-Z]{6,16}$/;
					if (!passwdRule.test(value)) {
						cb(new Error("请输入6-16位登录密码，不能用纯数字！"));
					}
				}
			};
			return {
				// 步骤定义
				step: 1,
				// Tooltip 文字提示展示方式
				placement: 'right',
				codeBtnText: '点击获取',
				isCkCodeBtn: false,
				time: 60,
				sms_timer: '',
				// 图片验证码ID值
				uuid: "",
				// 图片验证码
				verifycodeImgUrl: '',
				// 表单1属性定义
				stepForm1: {
					username: '',
					verifycode: ''
				},
				// 表单1空值失焦校验提示
				stepRules1: {
				  username: [
				    { required: true, trigger: "blur", message: "请输入登录帐号" }
				  ],
					verifycode: [
					  { required: true, trigger: "blur", message: "请输入图片验证码" }
					]
				},
				// 显示图片验证码
				isverifycode: false,
				// 表单2属性定义
				stepForm2: {
					verifycode: '',
					smsCode: '',
					password: ''
				},
				// 表单2空值失焦校验提示
				stepRules2: {
					verifycode: [
					  { required: true, trigger: "blur", message: "请输入" }
					],
				  smsCode: [
				    { required: true, trigger: "blur", message: "请输入" }
				  ],
				  password: [
				    { required: true,  trigger: "blur", message: "请输入密码"}
				  ],
				},
			};
		},
		created() {
			// 加载图片验证码
		  this.handleGetCaptcha();
		},
		methods: {
			// 点击刷新更换图片验证码
			changeVerifycodeImgUrl() {
			  this.handleGetCaptcha();
			},
			// 获取图片验证码
			handleGetCaptcha() {
			  getCaptcha().then((data) => {
			    this.verifycodeImgUrl = `data:image/jpg;base64,${data.base64}`;
			    this.uuid = data.uuid;
			  });
			},
			// step1提交
			submitStep1: _.debounce(function (stepForm1) {
				this.$refs[stepForm1].validate((valid) => {
				  if (valid) {
						const params = {
						  captcha: this.stepForm1.verifycode,
						  account: this.stepForm1.username,
						  uuid: this.uuid,
						  type: 2  //	1：手机；2：邮箱
						};
						// 发送验证码
						sendSms(params).then((data) => {
							this.step = 2;
							// 验证码发送成功后，获取返回的uuid
							this.uuid = data;
							this.$message({
							  type: "success",
							  message: "验证码已发送至您的邮箱，请注意查收！",
							});
							this.doInterval();
						})
						.catch(() => {
							this.handleGetCaptcha();
						});
					}
				});
			}, 300),
			// 获取验证倒计时
			doInterval() {
				this.isverifycode = false
				this.isCkCodeBtn	= true
				this.sms_timer = setInterval(() => {
				    this.time -- ;
				    this.codeBtnText = `${this.time}s`
				    if (this.time <= 0) {
				        this.isCkCodeBtn = false
				        this.time = 60
				        this.codeBtnText = '重新获取'
				        clearInterval(this.sms_timer)
				    }
				}, 1000)
			},
			// 点击获取验证码
			getCode() {
				this.isverifycode = true;
				// this.stepForm2.verifycode = '';
				this.stepForm2.smsCode = '';
			  const params = {
			    captcha: this.stepForm2.verifycode,
			    account: this.stepForm1.username,
			    uuid: this.uuid,
			    type: 2  //	1：手机；2：邮箱
			  };
			  // 发送验证码
			  sendSms(params).then((data) => {
			  	this.step = 2;
					// 验证码发送成功后，获取返回的uuid
					this.uuid = data;
			  	this.$message({
			  	  type: "success",
			  	  message: "验证码已发送至您的邮箱，请注意查收！",
			  	});
			  	this.doInterval();
			  })
			  .catch(() => {
			  	this.handleGetCaptcha();
			  });
			},
			// step2提交表单
			submitStep2: _.debounce(function (stepForm2) {
				// this.$refs[stepForm2].validate((valid) => {
				//   if (valid) {
						
				// 	}
				// });
				let passwdRule = /^(?![0-9]+$)[0-9A-Za-z|a-zA-Z]{6,16}$/;
				if(!passwdRule.test(this.stepForm2.password)) {
					this.$message({
					  type: "error",
					  message: "请输入6-16位登录密码，不能用纯数字！",
					});
				}else {
					const params = {
					  code: this.stepForm2.smsCode,
					  account: this.stepForm1.username,
					  password: md5(this.stepForm2.password),
					  uuid: this.uuid,
					  type: 2,
					};
					retrievePassword(params).then(() => {
					  this.$message({
					    type: "success",
					    message: "密码重置成功，请重新登录！",
					  });
						setInterval(() => {
							this.$router.push("/");
						}, 2000)
					});
				}
			}, 300),
			
		}
	}
</script>

<style lang="scss" scoped>
	.container {
		height: 100%;
		width: 100%;
		background: url("~@/assets/images/login-bg.jpg") no-repeat;
		background-size: cover;	
		overflow: auto;
		display: flex;
		flex-direction: column;
		
		.content-box {
			width: 420px;
			padding-top: 48px;
			padding-bottom: 48px;
			margin: 7% auto;
			background-color: #ffffff;
			border-radius: 4px;
			// box-shadow: $--box-shadow-base;
			box-shadow: 0 0 16px rgb(31 84 167 / 10%);
			// border: 2px solid #ecf0f5;
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;
			
			.logo {
				width: 86px; 
				height: 76px; 
			}
			
			.title {
				margin-top: 24px;
				font-size: 24px;
				color: #303133;
			}
			
			.sub-title {
				margin-top: 16px;
				font-size: 14px;
				color: #909399;
			}
			
			.login-form {
				margin-top: 32px;
				display: flex;
				flex-direction: column;
				align-items: center;
				justify-content: center;
			
				.item-input {
					width: 328px;
					// margin-bottom: 8px;
				}
			
				.item-button {
					width: 328px;
					margin-top: 24px;
				}
				
				.check-img-box {
					width: 100%;
					height: 40px;
					padding: 2px 2px;
					border: 1px solid #DCDFE6;
					box-sizing: border-box;
					border-radius: 4px;
					display: flex;
					align-items: center;
					justify-content: center;
					cursor: pointer;
					.verifycode-img {
						width: 100%;
						height: 100%;
					}
				}
				
				.forget-box {
					width: 328px;
					height: 24px;
					display: flex;
					flex-direction: row;
					justify-content: flex-end;
					align-items: center;
					.forgetPass {
						margin-left: auto;
						text-decoration:none ;
						color: #909399;
						font-size: 14px;
						&:hover {
							color: #409EFF;
						}
					}
				}
			}
			
			.tips-box {
				margin-top: 24px;
				display: flex;
				flex-direction: row;
				align-items: center;
				justify-content: center;
				.return {
					margin-left: auto;
					text-decoration:none ;
					color: #909399;
					font-size: 14px;
					&:hover {
						color: #409EFF;
					}
				}
			}
		}
	}
</style>
export const mixinCheckOrder = {
  methods: {
    //判断是否展示历史订单
    async handleCheckOrder() {
      const {
        bsgOtdHistoryOrder: { check },
      } = this.$utils.storage.get("user");
      if (check === 1) {
        this.route.history = "/bsg/otd/history";
      } else if (check === 0) {
        delete this.route.history;
      }
    },
  },
};

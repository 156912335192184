<template>
  <div class="index">
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: "index",
  data() {
    return {};
  },
};
</script>

<style lang="scss" scoped>
.index {
  height: 100%;
}
</style>

<template>
  <div class="container">
    <div class="content-box">
      <img class="logo" src="~@/assets/images/logo.png" fit="contain" />
      <p class="title">客户支持系统</p>
      <p class="sub-title">在线下单、查询报告和申请售后</p>
      <el-form
        :model="loginForm"
        :rules="loginRules"
        ref="loginForm"
        class="login-form"
        @submit.native.prevent
      >
        <el-form-item prop="username">
          <el-input
            type="text"
            v-model="loginForm.username"
            autocomplete="off"
            placeholder="登录帐号"
            prefix-icon="el-icon-user"
            class="item-input"
            :clearable="true"
          ></el-input>
        </el-form-item>
        <el-form-item prop="password">
          <el-input
            placeholder="登录密码"
            v-model="loginForm.password"
            prefix-icon="el-icon-lock"
            class="item-input"
            autocomplete="off"
            show-password
          ></el-input>
        </el-form-item>
        <el-form-item class="item-input">
          <el-row :gutter="16">
            <el-col :span="12">
              <el-form-item prop="verifycode">
                <el-input
                  type="text"
                  v-model="loginForm.verifycode"
                  autocomplete="off"
                  placeholder="图片验证码"
                  prefix-icon="el-icon-document-checked"
                  :clearable="true"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-tooltip
                content="计算结果为验证码，可点击刷新"
                placement="bottom"
                effect="light"
                :placement="placement"
              >
                <div class="check-img-box" @click="changeVerifycodeImgUrl()">
                  <img class="verifycode-img" :src="verifycodeImgUrl" />
                </div>
              </el-tooltip>
            </el-col>
          </el-row>
        </el-form-item>
        <el-form-item class="forget-box">
          <router-link to="/forget" class="forgetPass">忘记密码</router-link>
        </el-form-item>
        <el-form-item>
          <el-button
            native-type="=submit"
            type="primary"
            @click="submitForm('loginForm')"
            class="item-button"
            >登 录</el-button
          >
        </el-form-item>
      </el-form>
      <div class="tips-box">
        <span>建议使用浏览器</span>
        <el-tooltip
          content="Chrome浏览器，点击图标下载"
          placement="bottom"
          effect="light"
          :placement="placement"
        >
          <img
            class="chrome"
            src="~@/assets/images/chrome.png"
            @click="downloadChome"
          />
        </el-tooltip>
        <el-divider direction="vertical"></el-divider>
        <el-tooltip
          content="微软Edge浏览器，点击图标下载"
          placement="bottom"
          effect="light"
          :placement="placement"
        >
          <img
            class="edge"
            src="~@/assets/images/Edge.png"
            @click="downloadEdge"
          />
        </el-tooltip>
      </div>
      <div class="doc-box">
        <span @click="downloadAccountForm">下载开户申请表</span>
        <el-divider direction="vertical"></el-divider>
        <span @click="accessManual">查阅操作手册</span>
      </div>
    </div>
    <p class="copyright-wrap">
      <span class="desc">©上海中科新生命生物科技有限公司</span>
      <span class="lineY">|</span>
      <a
        href="https://beian.miit.gov.cn/"
        rel="nofollow"
        target="_blank"
        class="beian"
        ><img
          class="qrCode_img"
          src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAAAXNSR0IArs4c6QAAA35JREFUOBFdU1tsk3UU/323fteu7drSdmNdNyyIAxYncyKQSQw+4CVgCPpkMJGgD2riCzEmZOCNB8REBRI0ETUhBuV1I0ZSdQ4emMoIm7DZ7p5urPd+/fpd+n1/2yWa6Xk4Ob/k/H7JOed3KPwvxsll10NJ3Ts9oz01NjH3mmURbtu2tvPrPeJQundXPk7FjbUUdi1o1NTgn2+eG5x8uak2u9nRNbhCQUxMrPRem7Kndi8ufFNveXcth/kXPEI4kmAOf3x2/DPHzASfWEyhM6ehQ68g7GhItbT7bwzP7fn5yiv3Tnxy9c4/PHq1GCBs/uzx4yc/SJ0p1jgcaVkBd0eDmq6hPG8g9/lNHGxOwwy24/1Tv32q3jpx7D8C5I2j8kjK3HX5ZrunL1YBNbIIy6bh0gmc+SqIoqDy1a/YvcXEpZF1gaFbxl6Sfb2pIUIPDBDa/kN7vpnS+rQagYfWYOgMCGogj/sg7G+F4OGg5ywElSpKGlDX2wE7fnggQVh6x4EfxPs58mzYpYo814RppgXCC+uRaRfhRGUwW/xg9nUi8s6juD1lQRIEbAqUJfWvhX0vRu6JdMvMqKIW8VgzpyLWzeHCRRdWFmpo3eCHOVqAPpIBs1zfR0rH+QsOol0KQmwB2dTMZnbiW5FmfBW6Blti1RwO7qng/rKM7BcpNOczoKQaOB9BVLaxcnoMi3PAgb0m6FIBtot35JhBWJnq0h13ftKSjd7nHlBxbmcr3vqpD/0/zqNNcSDJJrJNflzXo4g8HMD+njwsMwBZdCc5d5fBSvKCqLuFrcvTgL84hi/f7sb3Pd0YvrsdwoYifFwJFxMheHcqOHOkCGnpLrJlGkpc2c57ihI1TYjgGf/wtJYcParOlVnJLCPUH0eFIWA1HbWSBXNdBEx9UPPGJCq0CN+DXpONbDyV3HjyPapxy+FZ4tu0dOxrYsw+k7+dhRQU0BQNwChoYGQXXG4e6kwOZpWGFAvDNPnvrP6PXuqgKH3ViT2BYYGhmRDv8cOzNQrOo0Cv6oAsgTgO8uNp2GAgx4PgWAus6HQEMehdNVIjSUvXNT4sX7MsJ89YKgSZgKpqoLQi7EIJQkCEy0tArCoQCM9K8Y4rRi5Zt1T9+RqpESSRYFPLlzorS5mnKVF7UpHlGOsiPoYXHJvi03w4mqpWMMS3RYZ++f3V7KFDlN3g/Q1vLH+EWrIHzwAAAABJRU5ErkJggg=="
          alt=""
        />
        沪ICP备14033860号</a
      >
    </p>
  </div>
</template>

<script>
import { getCaptcha, login } from "@/api/home/index";
import _ from "lodash";
import md5 from "md5";
import { order } from "@/utils/dict.js";
import { mixinCheckOrder } from "@/views/bsg/components/checkOrder/index.js";
import { getHistoryOrder } from "@/api/order/bsg/history";
import { checkOrder } from "@/api/order/bsg/history";
export default {
  mixins: [mixinCheckOrder],
  data() {
    return {
      // Tooltip 文字提示展示方式
      placement: "right",
      // 表单字段
      loginForm: {
        username: "",
        password: "",
        verifycode: "",
      },
      // 图片验证码ID值
      uuid: "",
      // 图片验证码
      verifycodeImgUrl: "",
      // 表单空值失焦提示
      loginRules: {
        username: [
          { required: true, trigger: "blur", message: "请输入登录帐号" },
        ],
        password: [
          { required: true, trigger: "blur", message: "请输入登录密码" },
        ],
        verifycode: [
          { required: true, trigger: "blur", message: "请输入验证码" },
        ],
      },
      asg: [12, 13, 15], //asg业务类型列表
      bsg: [21, 22, 23], //bsg业务类型列表
      csg: [31, 32, 33], //csg业务类型列表
      userBstp: null, //用户业务类型
      bstpList: order.bstpList, //字典中的业务类型列表
    };
  },
  created() {
    // 加载图片验证码
    this.handleGetCaptcha();
  },
  methods: {
    // 获取一级业务类型
    getFirstLevelBstp() {
      const bstpArr = [];
      for (const item of this.userBstp) {
        if (this.asg.includes(item)) {
          bstpArr.push("asg");
        }
        if (this.bsg.includes(item)) {
          bstpArr.push("bsg");
        }
        if (this.csg.includes(item)) {
          bstpArr.push("csg");
        }
      }
      return [...new Set(bstpArr)];
    },
    toAsg() {
      this.setUserBstp("asg");
      this.$router.push("/asg/order");
    },
    toBsg() {
      const bsgOtd = order.busiType.bsgOtd;
      const bsgOtdObj = order.bstpList.find((item) => item.value === bsgOtd);
      const hasBsgOtd = this.userBstp.find((item) => item === Number(bsgOtd));
			const bsgLtc = order.busiType.bsgLtc;
			const bsgLtcObj = order.bstpList.find((item) => item.value === bsgLtc);
			const hasLtcOtd = this.userBstp.find((item) => item === Number(bsgLtc));
      let route;
      if (hasLtcOtd) {
        //如果存在BSG-LTC业务类型，跳该路由，变更原因：客服反馈客户找不到LTC项目的入口
        route = bsgLtcObj.route;
      } else {
        //否则，跳第一个路由
        const first = this.userBstp[0];
        const target = order.bstpList.find(
          (item) => Number(item.value) === first
        );
        route = target.route;
      }
      this.setUserBstp(route);
      this.$router.push(route);
    },
    toCsg() {
      this.setUserBstp("csg");
      this.$router.push("/csg/order");
    },
    // 默认选择后，设置对应的业务类型
    setUserBstp(userBstpSelected) {
      const userBstp = this.userBstp.join(",");
      this.$utils.storage.setAttr("user", "userBstp", userBstp); //用户存在的业务类型
      this.$utils.storage.setAttr("user", "userBstpSelected", userBstpSelected); //用户选择的业务类型
    },
    // 默认选择业务类型登录
    defaultSelect(type) {
      if (type === "asg") {
        //asg
        this.toAsg();
      } else if (type === "bsg") {
        //bsg
        this.toBsg();
      } else if (type === "csg") {
        //csg
        this.toCsg();
      }
    },
    // 点击刷新更换图片验证码
    changeVerifycodeImgUrl() {
      this.handleGetCaptcha();
    },
    // 获取图片验证码
    handleGetCaptcha() {
      getCaptcha().then((data) => {
        this.verifycodeImgUrl = `data:image/jpg;base64,${data.base64}`;
        this.uuid = data.uuid;
      });
    },
    // 提交登录
    submitForm: _.debounce(function (loginForm) {
      this.$refs[loginForm].validate((valid) => {
        if (valid) {
          const params = {
            captcha: this.loginForm.verifycode,
            account: this.loginForm.username,
            password: md5(this.loginForm.password),
            uuid: this.uuid,
          };
          login(params)
            .then(async (data) => {
              const { token } = data;
              if (token) {
                this.$utils.storage.set("user", data);
                await this.handleCheckOrder(); //判断用户是否有历史订单
                // this.getBsgOtdHistoryOrder(); //获取用户历史订单
                this.userBstp = data.businessTypes.map(
                  (item) => item.businessType
                );
								// 当前只有BSG客户使用CSS，所以登录后直接去到BSG订单页面
								this.toBsg();
                // const bstps = this.getFirstLevelBstp();
                // if (bstps.length === 1) {
                //   //当一级用户类型只有一种时，默认选择该业务进行登录，无需用户手选。
                //   this.defaultSelect(bstps[0]);
                // } else {
                //   //当一级用户类型多种，跳到选择页，用户手选
                //   this.$router.push("/switch/switch");
                // }
              }
            })
            .catch((err) => {
              this.handleGetCaptcha();
            });
        }
      });
    }, 300),
    // 加载提交表单
    handleSubmitForm() {
      this.submitForm("loginForm");
    },
    // 下载chrome浏览器
    downloadChome() {
      window.open(
        "https://chorme.googlenav.com/00120/ChromiumSetup.exe",
        "_blank"
      );
    },
    // 下载edge浏览器
    downloadEdge() {
      window.open("https://www.microsoft.com/zh-cn/edge#evergreen", "_blank");
    },
    // 下载开户申请表
    downloadAccountForm() {
      window.open(
        "https://portal.aptbiotech.com:4443/cssguides/open-account-form.docx",
        "_blank"
      );
    },
    // 访问操作手册
    accessManual() {
      window.open(
        "https://portal.aptbiotech.com:4443/cssguides/css-manual.pdf",
        "_blank"
      );
    },
    // 获取bsgOtd历史订单数据列表
    async getBsgOtdHistoryOrder() {
      // 判断是否存在历史订单
      const {
        bsgOtdHistoryOrder: { check },
      } = this.$utils.storage.get("user");
      if (!check) return;

      // 开始获取数据
      this.$utils.storage.setAttr("user", "bsgOtdHistoryOrder", {
        check,
        status: 0,
      }); // 历史订单数据获取状态：0-获取中，1-获取成功，2-获取失败
      //请求配置
      const params = {
        config: {
          loading: false,
          timeout: 60000,
        },
        params: {
          pageNum: 1,
          pageSize: 10,
          query: "",
        },
      };
      getHistoryOrder(params)
        .then((data) => {
          this.$utils.storage.set("bsgOtdHistoryOrder", data);
          this.$utils.storage.setAttr("user", "bsgOtdHistoryOrder", {
            check,
            status: 1,
            lastTime: new Date().getTime(),
          });
        })
        .catch(() => {
          this.$utils.storage.setAttr("user", "bsgOtdHistoryOrder", {
            check,
            status: 2,
          });
        });
    },
    //判断是否展示历史订单
    async handleCheckOrder() {
      await checkOrder().then((data) => {
        if (data) {
          this.$utils.storage.setAttr("user", "bsgOtdHistoryOrder", {
            check: 1,
          }); // 1-展示
        } else {
          this.$utils.storage.setAttr("user", "bsgOtdHistoryOrder", {
            check: 0,
          }); // 0-不展示
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.container {
  height: 100%;
  width: 100%;
  background: url("~@/assets/images/login-bg.jpg") no-repeat;
  background-size: cover;
  overflow: auto;
  display: flex;
  flex-direction: column;

  .content-box {
    width: 420px;
    padding-top: 48px;
    padding-bottom: 48px;
    margin: 7% auto;
    background-color: #ffffff;
    border-radius: 4px;
    // box-shadow: $--box-shadow-base;
    box-shadow: 0 0 16px rgb(31 84 167 / 10%);
    // border: 2px solid #ecf0f5;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .logo {
      width: 86px;
      height: 76px;
    }

    .title {
      margin-top: 24px;
      font-size: 24px;
      color: #303133;
    }

    .sub-title {
      margin-top: 16px;
      font-size: 14px;
      color: #909399;
    }

    .login-form {
      margin-top: 32px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      .item-input {
        width: 328px;
        // margin-bottom: 8px;
      }

      .item-button {
        width: 328px;
        // margin: 22px auto 0 auto;
      }

      .check-img-box {
        width: 100%;
        height: 40px;
        padding: 2px 2px;
        border: 1px solid #dcdfe6;
        box-sizing: border-box;
        border-radius: 4px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        .verifycode-img {
          width: 100%;
          height: 100%;
        }
      }

      .forget-box {
        width: 328px;
        height: 24px;
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        align-items: center;
        .forgetPass {
          margin-left: auto;
          text-decoration: none;
          color: #909399;
          font-size: 14px;
          &:hover {
            color: #409eff;
          }
        }
      }
    }

    .tips-box {
      margin-top: 12px;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      font-size: 14px;
      color: #909399;
      .chrome {
        margin: 0 8px 0 16px;
        width: 22px;
        height: 22px;
        cursor: pointer;
      }
      .edge {
        margin: 0 0 0 8px;
        width: 22px;
        height: 22px;
        cursor: pointer;
      }
    }

    .doc-box {
      margin-top: 24px;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      font-size: 14px;
      color: #909399;
      cursor: pointer;
    }
  }

  .copyright-wrap {
    margin: 0 auto;
    display: flex;
    @mixin font {
      font-size: 14px;
      color: #ffffff;
    }
    .desc {
      @include font;
      @include center;
    }
    .lineY {
      padding: 0 10px;
      @include font;
      @include center;
    }
    .beian {
      display: flex;
      @include font;
      @include center;
      text-decoration: none;
    }
  }
}
</style>

/**
 * @desc BSG-OTD 历史接口
 */

import request from "@/utils/request";

// // 获取订单列表
// export function getHistoryOrder(config) {
//   return request({
//     url: '/apt-css/order/bsg/otd/legacy/orders',
//     method: "get",
//     ...config
//   });
// }

// 获取历史订单列表
export function getHistoryOrder(params) {
  return request({
    url: `/apt-css/order/bsg/otd/legacy/ordersInPage`,
    method: "post",
    data: params.params,
    ...params.config
  });
}

// 提交确认接口（confirmed：0-不同意，1-同意）
export function reportsConfirm(data) {
  return request({
    url: `/apt-css/order/bsg/otd/legacy/reportsConfirm`,
    method: "post",
    data,
  });
}

// 判断是否展示历史订单
export function checkOrder() {
  return request({
    url: "/apt-css/order/bsg/otd/legacy/ordersCheck",
    method: "get",
  });
}
